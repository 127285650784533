import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getOdRequestContent = createAsyncThunk('/odRequests/content', async () => {
	// const response = await axios.post(`${API_URL}/getSite`);
    // console.log(response);
    const response = [
        {
            name:"Chakit Sharma",
            designation:"Software",
            fromDate:"10-03-2024",
            toDate:"11-03-2024",
            reason:"Mumbai Visit",
            status:"Pending"
        }
    ]; 
	return response;
})

export const odRequestSlice = createSlice({
    name: 'odRequests',
    initialState: {
        isLoading: false,
        odRequests : []
    },
    reducers: {
    },
    extraReducers: {
		[getOdRequestContent.pending]: state => {
			state.isLoading = true
		},
		[getOdRequestContent.fulfilled]: (state, action) => {
			state.odRequests = action.payload
			state.isLoading = false
		},
		[getOdRequestContent.rejected]: state => {
			state.isLoading = false
		},
    }
})
export default odRequestSlice.reducer