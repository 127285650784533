import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getLeaveMasterContent = createAsyncThunk('/leaveMasters/content', async () => {
	const response = await axios.get(`${API_URL}/getLeaveType`);
    console.log(response);
	return response;
})

export const reimbursementMasterSlice = createSlice({
    name: 'leaveMasters',
    initialState: {
        isLoading: false,
        leaveMasters : []
    },
    reducers: {
    },
    extraReducers: {
		[getLeaveMasterContent.pending]: state => {
			state.isLoading = true
		},
		[getLeaveMasterContent.fulfilled]: (state, action) => {
			state.leaveMasters = action.payload.data
			state.isLoading = false
		},
		[getLeaveMasterContent.rejected]: state => {
			state.isLoading = false
		},
    }
})
export default reimbursementMasterSlice.reducer