import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getIssueBelongingSliceContent = createAsyncThunk('/beloningLists/content', async () => {
    // var userData=JSON.parse(localStorage.getItem("userData"));
	// const response = await axios.post(`${API_URL}/getLeads`, {});
	// return response;
    const response = [
        {
            name:"Chakit Sharma",
            belongingName:"Laptop",
            issueDate:"10-03-2024",
            returnDate:"10-04-2024",
        }
    ];    
    return response;
})

export const belongingSlice = createSlice({
    name: 'beloningLists',
    initialState: {
        isLoading: false,
        beloningLists : []
    },
    reducers: {
    },
    extraReducers: {
		[getIssueBelongingSliceContent.pending]: state => {
			state.isLoading = true
		},
		[getIssueBelongingSliceContent.fulfilled]: (state, action) => {
			state.beloningLists = action.payload
			state.isLoading = false
		},
		[getIssueBelongingSliceContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default belongingSlice.reducer