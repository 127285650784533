import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";

export const getCompanyContent = createAsyncThunk('/Companys/content', async () => {
    // var userData=JSON.parse(localStorage.getItem("userData"));
	// const response = await axios.post(`${API_URL}/getLeads`, {});
	// return response;
    const response = [
        {
            gstin: "123456",
            tan: "xyz",
            legalname: "decg",
            cob: "legal",
            dol: "28-09-2001",
            top: "abc",
            OwnerName: "Chakit Sharma",
            companyMobileNo: "8455082405"
        }
    ];    
    return response;
})

export const companySlice = createSlice({
    name: 'companys',
    initialState: {
        isLoading: false,
        companys : []
    },
    reducers: {
    },
    extraReducers: {
		[getCompanyContent.pending]: state => {
			state.isLoading = true
		},
		[getCompanyContent.fulfilled]: (state, action) => {
			state.companys = action.payload
			state.isLoading = false
		},
		[getCompanyContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export default companySlice.reducer