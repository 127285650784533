import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getSiteContent = createAsyncThunk('/siteMasters/content', async () => {
	// const response = await axios.post(`${API_URL}/getSite`);
    // console.log(response);
    const response = [
        {
            siteName:"Knewx office",
            siteAddress:"Phenoix mall",
            siteState:"Madhya Pradesh",
            siteCity:"Indore",
            siteCountry:"India",
            siteManager:"Chakit Sharma"
        }
    ]; 
	return response;
})

export const siteSlice = createSlice({
    name: 'siteMasters',
    initialState: {
        isLoading: false,
        siteMasters : []
    },
    reducers: {
    },
    extraReducers: {
		[getSiteContent.pending]: state => {
			state.isLoading = true
		},
		[getSiteContent.fulfilled]: (state, action) => {
			state.siteMasters = action.payload
			state.isLoading = false
		},
		[getSiteContent.rejected]: state => {
			state.isLoading = false
		},
    }
})
export default siteSlice.reducer