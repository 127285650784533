import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import userDataSlice from '../features/common/userDataSlice'
import companySlice from '../features/user/slice/CompanySplice'
import userSlice from '../features/user/slice/usersSlice'
import SubUserSplic from '../features/user/slice/SubUserSplic'
import reimbursementMasterSlice from '../features/user/slice/reimbursementMasterSlice'
import leaveMasterSplice from '../features/user/slice/leaveMasterSplice'
import siteSlice from '../features/user/slice/SiteSlice'
import odRequestSlice from '../features/user/slice/onDutyRequestSlice'
import belongingSlice from '../features/user/slice/belongingSlice'

const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  userDataSlice :userDataSlice,
  company : companySlice,
  user: userSlice,
  subuser: SubUserSplic,
  reimbursementMaster:reimbursementMasterSlice,
  leaveMaster:leaveMasterSplice,
  siteSlice:siteSlice,
  onDutyRequest:odRequestSlice,
  belonging:belongingSlice
}

export default configureStore({
    reducer: combinedReducer
})