import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getSubUserContent = createAsyncThunk('/subusers/content', async () => {
	// const response = await axios.get(`${API_URL}/getSubUserList`);
    // console.log(response);
	// return response;
    const response = [
        {
            name:"Chakit Sharma",
            designation:"Software Developer",
            gender:"Male",
            registeredMobileNo:"8455082405",
            emailId:"Chakitsharma444@gmail.com"
        }
    ];  
    return response;
})

export const SubUserSlice = createSlice({
    name: 'subusers',
    initialState: {
        isLoading: false,
        subusers : []
    },
    reducers: {
    },
    extraReducers: {
		[getSubUserContent.pending]: state => {
			state.isLoading = true
		},
		[getSubUserContent.fulfilled]: (state, action) => {
			state.subusers = action.payload
			state.isLoading = false
		},
		[getSubUserContent.rejected]: state => {
			state.isLoading = false
		},
    }
})

export const { addNewLead, deleteLead } = SubUserSlice.actions

export default SubUserSlice.reducer