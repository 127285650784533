import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getReimbursementContent = createAsyncThunk('/reimbursementMasters/content', async () => {
	const response = await axios.post(`${API_URL}/getReibursement`);
    console.log(response);
	return response;
})

export const reimbursementMasterSlice = createSlice({
    name: 'reimbursementMasters',
    initialState: {
        isLoading: false,
        reimbursementMasters : []
    },
    reducers: {
    },
    extraReducers: {
		[getReimbursementContent.pending]: state => {
			state.isLoading = true
		},
		[getReimbursementContent.fulfilled]: (state, action) => {
			state.reimbursementMasters = action.payload.data
			state.isLoading = false
		},
		[getReimbursementContent.rejected]: state => {
			state.isLoading = false
		},
    }
})
export default reimbursementMasterSlice.reducer