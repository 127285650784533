import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from "../../../utils/constant";
import axios from "axios";


export const getUserContent = createAsyncThunk('/users/content', async () => {
	const response = await axios.get(`${API_URL}/getUsers`);
    console.log(response);
	return response;
})

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        isLoading: false,
        users : []
    },
    reducers: {
    },
    extraReducers: {
		[getUserContent.pending]: state => {
			state.isLoading = true
		},
		[getUserContent.fulfilled]: (state, action) => {
			state.users = action.payload.data
			state.isLoading = false
		},
		[getUserContent.rejected]: state => {
			state.isLoading = false
		},
    }
})
export default usersSlice.reducer